import React, { Component } from 'react';
import { Link } from 'react-router-dom';
export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovered: false, // State to track hover effect
    };
  }

  // Handle mouse enter and leave to set hover state
  handleMouseEnter = () => {
    this.setState({ isHovered: true });
  };

  handleMouseLeave = () => {
    this.setState({ isHovered: false });
  };
  render() {
    let resumeData = this.props.resumeData;
    const linkStyle = {
      fontWeight: 'bold',
      textDecoration: this.state.isHovered ? 'none' : 'underline', // No underline by default
      color: this.state.isHovered ? '#0056b3' : 'rgba(212, 159, 12, 1)', // Change color on hover
      padding: '0 5px',
      transition: 'color 0.3s ease, text-decoration 0.3s ease, background-color 0.3s ease',
      cursor: 'pointer',
      backgroundColor: this.state.isHovered ? 'rgba(0, 123, 255, 0.1)' : 'transparent', // Highlight background on hover
      boxShadow: this.state.isHovered ? '0 2px 5px rgba(0, 123, 255, 0.3)' : 'none', // Shadow on hover
    };
    return (
      <React.Fragment>
        {/*generated code*/}
        <header id="home">
          <nav id="nav-wrap">
            <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
            <a className="mobile-btn" href="#" title="Hide navigation">Hide navigation</a>
            <ul id="nav" className="nav">
              <li className="current"><a className="smoothscroll" href="#home">Home</a></li>
              <li><a className="smoothscroll" href="#about">About</a></li>
              <li><a className="smoothscroll" href="#resume">Resume</a></li>
              <li><a className="smoothscroll" href="#portfolio">Works</a></li>
              <li><a className="smoothscroll" href="#quotes">Fun Quotes</a></li>
              <li><a className="smoothscroll" href="#contact">Contact</a></li>
              <li><a href="/lotr">My Hobby</a></li>
            </ul>
          </nav>

          <div className="row banner">
            <div className="banner-text">
              <h1 className="responsive-headline">Hi, I'm {resumeData.name} Nice to meet you!</h1>
              <h3> {resumeData.role} Awesome! I'm a Scala Developer, passionate about coding impactful solutions and a huge fan of{' '}
  <Link to="/lotr" 
     style={linkStyle}
     onMouseEnter={this.handleMouseEnter} // Trigger hover effect
     onMouseLeave={this.handleMouseLeave} // Remove hover effect
      >
    The Lord of the Rings
  </Link>. To learn more about me, just scroll down or explore the links below. Enjoy!
</h3>
              <hr />
              <ul className="social">
                {
                  resumeData.socialLinks && resumeData.socialLinks.map(item => {
                    return (
                      <li key={item.name}>
                        <a href={item.url} target="_blank"><i className={item.className}></i></a>
                      </li>
                    )
                  }
                  )
                }
              </ul>
            </div>
          </div>

          <p className="scrolldown">
            <a className="smoothscroll" href="#about"><i className="icon-down-circle"></i></a>
          </p>

        </header>
      </React.Fragment>
    );
  }
}
