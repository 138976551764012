let resumeData = {
  "name": "Alexay Lisitsyn",
  "role": "Still here?",
  "linkedinId": "alexeylisitsyn31",
  "skypeid": "alexey.lisitsyn93",
  "emailid": "alexeylisitsyn31@gmail.com",
  "roleDescription": "Awesome! I'm a Scala Developer, passionate about coding impactful solutions and a huge fan of The Lord of the Rings. To learn more about me, just scroll down or explore the links below. Enjoy!",
  "socialLinks": [
    {
      "name": "linkedin",
      "url": "https://www.linkedin.com/in/alexeylisitsyn31",
      "className": "fa fa-linkedin"
    },
    {
      "name": "github",
      "url": "https://github.com/Baymanka",
      "className": "fa fa-github"
    },
    {
      "name": "skype",
      "url": "https://join.skype.com/invite/k78BuRCfGM63",
      "className": "fa fa-skype"
    },
    {
      "name": "HackerRank",
      "url": "https://www.hackerrank.com/baymanka1521",
      "className": "fa fa-line-chart"
    },
    {
      "name": "Codingame",
      "url": "https://www.codingame.com/profile/b169e4b51fc6c2402827f89e9d504d4b1308322",
      "className": "fa fa-cubes"
    },
    {
      "name": "cv",
      "url": "\\images\\CV_Lisitsyn.pdf",
      "className": "fa fa-download"
    }
  ],
  "aboutme": "I hold a Master of Engineering degree in Computer Science and Control Systems and have been working in the IT industry for several years. In parallel, I earned a Bachelor of Arts degree in Jurisprudence through evening classes. I began my career while still at university as an intern at UniCredit Bank, where I was responsible for functional testing and SQL query tasks.|After my internship, I transitioned to the role of Performance Testing Engineer at Performance Lab. There, I was involved in various development tasks in Java, including writing testing scripts, working with emulators, and automating work processes. Although this was an interesting field, I soon realized my true passion lay in software development.|At TEKO, as a Scala Developer, I developed microservices and maintained legacy code for a payment processing platform. I enjoyed deepening my expertise in Scala, Akka, and MongoDB while working on cash desk and personal account software. Tackling challenges like Akka concurrency and parallelism, as well as implementing a cryptographic protocol, was particularly rewarding.|Currently, I am a Software Developer at AdviceLink, where I am building a financial CRM system. This role allows me to further expand my skills with ScalaJS, Play, and PostgreSQL. My career goal is to become a highly skilled specialist, explore opportunities abroad, and continue to stay passionate and deeply engaged in my work.",
  "address": "New Zealand, Queenstown",
  // "website":"https://www.qwerty.com",
  "education": [
    {
      "UniversityName": "Bauman Moscow State Technical University",
      "type": "Master of Engineering",
      "specialization": "Department «Computer Science and Control Systems». Specialty «Automatic Control Systems for Flight Vehicles»",
      "YearOfPassing": "2016"
    },
    {
      "UniversityName": "Bauman Moscow State Technical University",
      "type": "Bachelor of Arts",
      "specialization": "Department «Jurisprudence». Specialty «Jurisprudence, intellectual property and forensic examination»",
      "YearOfPassing": "2016"
    }
  ],
  "work": [
    {
      "CompanyName": "\"AdviceLink\" LTD",
      "specialization": "Developing CRM system(HTTP/Play/PostgeSQL/ScalaJS); Interaction with tech support to investigate problems, perform root-cause analysis and deliver bug-fixes; Legacy code maintenance",
      "Link": "https://lfg.co.nz/about/advice-link-2/",
      "className": "fa fa-external-link",
      "Starting": "Sep 2022",
      "Leaving": "Present",
      "job": "Software Developer",
      "Achievements": "Some Achievements"
    },
    {
      "CompanyName": "\"TEKO\" LLC",
      "specialization": "Developing backend web services(HTTP/Akka); Integrating various partner’s protocols; Interaction with tech support to investigate problems, perform root-cause analysis and deliver bug-fixes; Legacy code maintenance",
      "Link": "https://teko.io",
      "className": "fa fa-external-link",
      "Starting": "Oct 2017",
      "Leaving": "Jul 2022",
      "job": "Middle Scala Developer",
      "Achievements": "Some Achievements"
    },
    {
      "CompanyName": "\"Performance Lab\" LLC",
      "specialization": "Participation in projects on stress testing and performance optimization; Analysis of test results; Scripting testing scenarios; Developing emulators; Creating test strategy",
      "Link": "https://www.performance-lab.ru",
      "className": "fa fa-external-link",
      "Starting": "Oct 2016",
      "Leaving": "Oct 2017",
      "job": "Performance Test Engineer",
      "Achievements": "Some Achievements"
    },
    {
      "CompanyName": "\"UniCredit Bank\" JSC",
      "specialization": "Checking service providers in the Internet bank system for compliance with data from the provider (\"Eleksnet\"); Service providers logs preparation for Internet and mobile banking; Preparation of reference information for clients on Internet and mobile banks for publication on the Bank's website (user's guide, Help, etc.); Preparation of statistical reports on systems of remote service (Internet and mobile banking); Participation in the launch of promotion activities for remote service channels",
      "Link": "https://www.unicreditbank.ru/en/",
      "className": "fa fa-external-link",
      "Starting": "Nov 2014",
      "Leaving": "Oct 2015",
      "job": "Intern",
      "Achievements": "Some Achievements"
    },
    {
      "CompanyName": "\"Bee Pitron\" LLC",
      "Link": "http://beepitron.com/en/",
      "className": "fa fa-external-link",
      "specialization": "Aircraft electrical network design; Creating computer models of aircraft individual parts",
      "Starting": "Jun 2013",
      "Leaving": "Oct 2013",
      "job": "Assistant to the Design Engineer",
      "Achievements": "Some Achievements"
    }
  ],
  "skillsDescription": "Some of my skills",
  "skills": [
    {
      "skillname": "Scala",
      "skillstart": "2017/10/17"
    },
    {
      "skillname": "Java",
      "skillstart": "2016/10/10",
      "skillstop": "2017/10/17"
    },
    {
      "skillname": "Linux",
      "skillstart": "2016/10/10"
    },
    {
      "skillname": "MongoDB",
      "skillstart": "2017/10/17",
      "skillstop": "2022/07/22"
    },
    {
      "skillname": "Akka",
      "skillstart": "2017/10/17",
      "skillstop": "2022/07/22"
    },
    {
      "skillname": "JIRA",
      "skillstart": "2016/10/10"
    },
    {
      "skillname": "SQL",
      "skillstart": "2021/10/10"
    }
  ],
  "portfolio": [

    {
      "name": "Business Intelligence Service",
      "descrip": "Scala web service that aggregates and stores real time and archival statistic.",
      "imgurl": "images/portfolio/bi_service.jpg"
    },
    {
      "name": "Report Service",
      "descrip": "Scala web service that produces an Excel file with regular and pivot tables from database or local services data and sends in by email to clients.",
      "imgurl": "images/portfolio/api_scala.png"
    }
  ],
  "quotes": [
    {
      "description": "Walking on water and developing software from a specification are easy if both are frozen.",
      "name": "Edward V. Berard"
    },
    {
      "description": "The best method for accelerating a computer is the one that boosts it by 9.8 m/s2.",
      "name": "Anonymous"
    },
    {
      "description": "In order to understand recursion, one must first understand recursion.",
      "name": "Anonymous"
    }
  ]
}

export default resumeData
