// src/components/lotr/Lotr.js
import React from 'react';
import './Lotr.css';
import ImageCarousel from '../../ImageCarousel'; // Relative import of ImageCarousel.js

const Lotr = () => {
  const videos = [
    '/images/gifs/lotr_gandalf_enter_hobbiton.mp4',
    '/images/gifs/lotr_water_mill.mp4',
    '/images/gifs/lotr_gandalf_bridge_carriage.mp4',
    '/images/gifs/lotr_gandalf_bagend_door.mp4',
    '/images/gifs/road_breakfast.mp4',
    '/images/gifs/gandalf_isengard.mp4',
    '/images/gifs/off_the_road.mp4',
    '/images/gifs/off_the_road2.mp4',
    '/images/gifs/off_the_road3.mp4',
    '/images/gifs/off_the_road4.mp4',
    '/images/gifs/ford_bruinen.mp4',
    '/images/gifs/ford_bruinen2.mp4',
    '/images/gifs/ford_bruinen3.mp4',
    '/images/gifs/rivendell_arc.mp4',
    '/images/gifs/rivendell_arc2.mp4',
    '/images/gifs/rivendell_arc3.mp4',
    '/images/gifs/moria_exit.mp4',
    '/images/gifs/moria_exit2.mp4',
    '/images/gifs/moria_exit3.mp4',
    '/images/gifs/boats_arrive.mp4',
    '/images/gifs/aragorn_tree.mp4',
    '/images/gifs/aragorn_tree2.mp4',
    '/images/gifs/tree_hide.mp4',
    '/images/gifs/tree_jump.mp4',
    '/images/gifs/frodo_leave.mp4',
    '/images/gifs/mordor_cliff.mp4',
    '/images/gifs/mordor_cliff2.mp4',
    '/images/gifs/pike_head.mp4',
    '/images/gifs/helmet_kick.mp4',
    '/images/gifs/gandalf_call.mp4',
    '/images/gifs/gandalf_horse.mp4',
    '/images/gifs/medusel_look.mp4',
    '/images/gifs/medusel_look2.mp4',
    '/images/gifs/medusel_village.mp4',
    '/images/gifs/medusel_lookout.mp4',
    '/images/gifs/medusel_lookout2.mp4',
    '/images/gifs/medusel_lookout3.mp4',
    '/images/gifs/medusel_grave.mp4',
    '/images/gifs/gollum_fishing.mp4',
    '/images/gifs/gollum_fishing2.mp4',
    '/images/gifs/gollum_rabbits.mp4',
    '/images/gifs/gollum_stew.mp4',
    '/images/gifs/army_lookout.mp4',
    '/images/gifs/elephant_lookout.mp4',
    '/images/gifs/isengard_view.mp4',
    '/images/gifs/warg_search.mp4',
    '/images/gifs/warg_drop.mp4',
    '/images/gifs/warg_kill.mp4',
    '/images/gifs/legolas_lookdown.mp4',
    '/images/gifs/pass_of_dead.mp4',
    '/images/gifs/lotr_sam_return.mp4',
    '/images/gifs/hobbit_bagend_hill_tree_view.mp4',
    '/images/gifs/hobbit_bagend_party_tree_view.mp4',
    '/images/gifs/hobbit_bridge_look.mp4'
  ];

  return (
    <div className="lotr">
      <h1>In pursuit of The Lord of the Rings</h1>
      <ImageCarousel videos={videos} interval={5000} />
    </div>
  );
};

export default Lotr;
