import React, { useState, useEffect, useRef } from 'react';
import './css/ImageCarousel.css';
import CarouselVideo from './CarouselVideo';
import VideoCarousel from './VideoCarousel';

const ImageCarousel = ({ videos, interval }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Track video loading state
  const [showClipsButtons, setShowClipsButtons] = useState(false);
  const [videoType, setVideoType] = useState(null);
  const [nextVideoKey, setNextVideoKey] = useState(0); // Key for next video to force re-render
  const videoRef = useRef(null); // Reference for the video element

  const currentVideo = videos[currentIndex]; // Current video based on index

  useEffect(() => {
    const timer = setInterval(() => {
      if (isOpen && !isLoading) {
        handleNext(); // Only trigger next if no video is loading
      }
    }, interval);

    return () => clearInterval(timer);
  }, [isOpen, isLoading, currentIndex, interval]);

  // Handle video load event
  const handleVideoLoad = () => {
    setIsLoading(false); // Stop loading once the video has been loaded
  };

  // Handle video error
  const handleVideoError = () => {
    setIsLoading(false); // Stop loading if the video fails to load
  };

  // Transition to the next video
  const handleNext = () => {
    setIsLoading(true); // Start loading the next video
    setNextVideoKey((prevKey) => prevKey + 1); // Update the key to force re-render of the video
    setCurrentIndex((prevIndex) => (prevIndex + 1) % videos.length); // Transition to the next video
  };

  // Transition to the previous video
  const handlePrev = () => {
    setIsLoading(true); // Start loading the previous video
    setNextVideoKey((prevKey) => prevKey + 1); // Update the key to force re-render of the video
    setCurrentIndex((prevIndex) => (prevIndex - 1 + videos.length) % videos.length); // Transition to previous video
  };

  // Toggle carousel open/close
  const toggleCarousel = () => {
    setIsOpen((prevState) => !prevState);
  };

  // Toggle visibility of Clips section
  const toggleClipsButtons = () => {
    setShowClipsButtons((prevState) => !prevState);
  };

  // Open specific video clips carousel
  const toggleVideoCarousel = (videoId) => {
    setVideoType(videoId);
    setIsVideoOpen(true);
  };

  // Close video carousel
  const handleCloseVideo = () => {
    setIsVideoOpen(false);
  };

  // Close the main carousel and video carousel
  const handleClose = () => {
    setIsOpen(false);
    setIsVideoOpen(false);
    setShowClipsButtons(false);  // Ensure this is closed when the main carousel is closed
  };

  return (
    <div>
      {/* Open Carousel Button */}
      {!isOpen && !isVideoOpen && (
        <div className="open-carousel-btn-container">
          <button onClick={toggleCarousel} className="open-carousel-btn">
            Locations
          </button>

          <div className={`clips-btn-container ${showClipsButtons ? 'show-buttons' : ''}`}>
            <div onClick={toggleClipsButtons} className="clips-title">Videos</div>
            {showClipsButtons && (
              <div className="clips-btns">
                <button onClick={() => toggleVideoCarousel('FOTR')} className="clips-btn">
                  The Fellowship of the Ring
                </button>
                <button onClick={() => toggleVideoCarousel('TTT')} className="clips-btn">
                  The Two Towers
                </button>
              </div>
            )}
          </div>
        </div>
      )}

      {/* Carousel Container */}
      {isOpen && (
        <div className={`carousel-container ${isOpen ? 'open' : ''}`}>
          <div className="carousel-overlay" onClick={handleClose} />

          {/* Render current video (hidden while loading) */}
          <div className={`carousel-video-container ${isLoading ? 'hidden' : ''}`}>
            <CarouselVideo
              key={nextVideoKey} // Unique key to force re-render
              videoSrc={currentVideo}
              onLoad={handleVideoLoad}
              onError={handleVideoError}
              isLoading={isLoading}
            />
          </div>

          {/* Carousel Controls */}
          <button className="carousel-control prev" onClick={handlePrev}>
            &#10094;
          </button>
          <button className="carousel-control next" onClick={handleNext}>
            &#10095;
          </button>
          <button className="close-btn" onClick={handleClose}>
            &#10006;
          </button>
        </div>
      )}

      {/* Video Carousel (if opened) */}
      {isVideoOpen && videoType && (
        <VideoCarousel videoType={videoType} handleClose={handleCloseVideo} />
      )}
    </div>
  );
};

export default ImageCarousel;
