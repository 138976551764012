import React, { Component, Fragment } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Header from './components/header/header';
import About from './components/about/about';
import Resume from './components/resume/resume';
import Portfolio from './components/portfolio/portfolio';
import Quotes from './components/quotes/quotes';
import ContactUs from './components/contactus/contactus';
import Footer from './components/footer/footer';
import Lotr from './components/lotr/Lotr';
import resumeData from './resumeData';
class App extends Component {
  render() {
    return (
      <div className="App">
        <Routes>
          <Route
            path="/"
            element={
              <Fragment>
                <Header resumeData={resumeData} />
                <About resumeData={resumeData} />
                <Resume resumeData={resumeData} />
                <Portfolio resumeData={resumeData} />
                <Quotes resumeData={resumeData} />
                <ContactUs resumeData={resumeData} />
                <Footer resumeData={resumeData} />
              </Fragment>
            }
          />
          <Route path="/lotr" element={<Lotr />} />
        </Routes>
      </div>
    );
  }
}

export default App;
