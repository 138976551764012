// src/VideoCarousel.js
import React from 'react';
import './css/VideoCarousel.css'; // Assuming your CSS for the video carousel

const VideoCarousel = ({ videoType, handleClose }) => {
  const videoSources = {
    FOTR: '/images/clips/FOTR.mp4',
    TTT: '/images/clips/TTT.mp4',
    // You can add more video sources as needed
  };

  const videoSource = videoSources[videoType];

  // Handle right-click to prevent download
  const handleRightClick = (e) => {
    e.preventDefault(); // Prevent the right-click context menu
  };

  return (
    <div className="video-carousel-container">
      <div className="video-overlay" onClick={handleClose} />
      <div className="video-carousel">
        <video
          controls
          controlsList="nodownload" // Remove the download option in the controls
          autoPlay
          muted={false} // Set muted to false if you want sound
          preload="auto" // Load video metadata and part of the video for faster playback
          onContextMenu={handleRightClick} // Disable right-click on the video
        >
          <source src={videoSource} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <button className="close-btn" onClick={handleClose}>
          &#10006;
        </button>
      </div>
    </div>
  );
};

export default VideoCarousel;
