// CarouselVideo.js
import React, { useEffect, useRef, useState } from 'react';

const CarouselVideo = ({ videoSrc, onLoad, onError, isLoading }) => {
  const videoRef = useRef(null);

  const handleVideoLoad = () => {
    onLoad(); // Notify parent that video has loaded
  };

  const handleVideoError = () => {
    onError(); // Notify parent if video failed to load
  };

  useEffect(() => {
    // Reset the video when it changes
    if (videoRef.current) {
      videoRef.current.load(); // This will reload the video element
    }
  }, [videoSrc]); // Re-run only when video source changes

  return (
    <video
      ref={videoRef}
      src={videoSrc}
      style={{
        height: '100vh',
        width: 'auto',
        opacity: isLoading ? 0 : 1, // Hide video if loading
        transition: 'opacity 1s ease',
      }}
      loop
      autoPlay
      muted
      playsInline
      onLoadedData={handleVideoLoad} // Trigger when the video is loaded
      onError={handleVideoError} // Handle error if video fails to load
    />
  );
};

export default CarouselVideo;
